@import "../../../../mixins";

.my-tile {
  padding: 16px 0;
  border-bottom: 1px solid #ebebeb;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &-param {
    display: flex;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-label {
    width: 40px;
    object-fit: contain;
    @include DefaultFont;
    color: #939393;
  }

  &-value {
    @include DefaultFont;
    font-weight: bold;
    color: #494949;
  }

  &-code {
    @include LinkColor;
    text-decoration: underline;
  }
}