@import url(https://fonts.googleapis.com/css?family=Lato);
body {
  margin: 0;
  padding: 0;
  font-family: Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: #025089;
  text-decoration: underline;
}

body {
  background-color: #fff;
}

.App {
  min-height: 100vh;
}

.Popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.Popup-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: auto;
}

.PopupWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  position: relative;
  min-width: 504px;
  max-width: 752px;
  min-height: 276px;
  max-height: 95vh;
  flex-direction: column;
  font-family: Lato;
  padding: 68px 28px;
}

.PopupBack {
  position: absolute;
  top: 24px;
  right: 24px;
}

.PopupContent {
  overflow: auto;
  padding: 0 58px;
}
.PopupContent h1 {
  font-size: 32px;
}
.PopupContent h2 {
  font-size: 24px;
}
.PopupContent h1, .PopupContent h2 {
  font-weight: bold;
  letter-spacing: -0.3px;
  color: #494949;
  text-align: left;
  margin-bottom: 24px;
}
.PopupContent p {
  margin-bottom: 18px;
}
.PopupContent p + h2, .PopupContent ul + h2 {
  margin-top: 40px;
}
.PopupContent a {
  color: #025089;
}
.PopupContent hr {
  margin-top: 40px;
  margin-bottom: 20px;
}

.PopupLink {
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
}

@-webkit-keyframes load {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes load {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.LoadBar {
  position: relative;
  width: 100%;
  height: 10px;
  margin-top: 50px;
  background-color: #f2f2f2;
  border-radius: 2px;
}
.LoadBar:before {
  content: "";
  display: block;
  height: 10px;
  -webkit-filter: blur(3px);
          filter: blur(3px);
  border-radius: 2px;
  background-color: #ffe003;
  -webkit-animation: load 1s 1 ease-in-out;
          animation: load 1s 1 ease-in-out;
}

.LoadingScreen {
  padding: 40px 120px;
}

.FloatingTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  position: absolute;
  left: calc(50vw - 113px);
  top: 10px;
  z-index: 10;
  width: 224px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: 166px 16px;
  background-position: center center;
}

.WhitePaper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  text-align: center;
  position: absolute;
  z-index: 3;
  right: 30px;
  bottom: 30px;
  text-decoration: none;
  padding: 0 17px;
  height: 40px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  color: #494949;
  letter-spacing: -0.2px;
}

#YouOwnLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 5;
  padding: 0 17px;
  height: 40px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: #494949;
}

.YouOwnNull {
  font-size: 16px;
  text-align: center;
}
.YouOwnNull a {
  display: block;
  text-decoration: none;
  color: #025089;
}

#SelectedLandsInfo {
  position: absolute;
  left: 50%;
  top: 120px;
  width: 400px;
  height: 60px;
  line-height: 165%;
  background-color: #aaa;
  z-index: 5;
  padding: 15px;
  border-radius: 2px;
  margin-left: -200px;
  text-align: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
}

#TitleBar {
  background-color: #c30000;
  margin: 0;
  padding: 10px 0;
  top: 0;
}
#TitleBar h1 {
  font-size: 40px;
  margin-right: 20px;
}
#TitleBar h1, #TitleBar h3 {
  display: inline;
}
#TitleBar h3 {
  vertical-align: 5px;
  color: #fff;
}
#TitleBar h3 a {
  color: #fff;
}
#TitleBar img {
  height: 40px;
}
#TitleBar a {
  color: #fff;
}

#BuyBar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  text-align: center;
  height: 60px;
  top: 70px;
  z-index: 5;
  position: absolute;
}
#BuyBar button {
  margin-left: 10px;
}

div, button, a {
  object-fit: contain;
}

button {
  border-style: none;
  border-width: 0px;
  border-radius: 7px;
  cursor: pointer;
}

.content {
  position: relative;
  background-color: #f9f9f9;
  min-height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
  padding: 20px;
}
@media only screen and (max-width: 600px) {
  .content {
    padding: 0;
  }
}

#land {
  width: 410px;
  height: 276px;
  left: 50%;
  margin-left: -205px;
  position: absolute;
  top: 20px;
  z-index: 5;
  object-fit: contain;
  border-radius: 6px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
}

#BuyBarDetail {
  color: #555;
  font-size: 90%;
}

#BuyBar.hasMetadata {
  height: 50px;
}

button.linkStyle {
  color: #025089;
  font-size: 16px;
  background-color: transparent;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
}

button.minorStyle {
  font-size: 14px;
  color: #858585;
  font-weight: bold;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

#selectedCode {
  cursor: pointer;
  text-decoration: underline;
}
.map-wrapper {
  position: relative;
  height: 100%;
}
.map-wrapper-header {
  display: flex;
  margin-bottom: 20px;
}
@media only screen and (max-width: 600px) {
  .map-wrapper-header {
    margin-bottom: 5px;
  }
}
.map-wrapper-header form {
  height: 46px;
  flex-grow: 1;
  display: flex;
}
.map-wrapper-header form input {
  height: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  border: 1px solid #e3e3e3;
  border-right: none;
  outline: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #494949;
  padding: 14px 20px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.map-wrapper-header form button {
  height: 100%;
  padding: 14px 34px;
  box-sizing: border-box;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #f0f0f0;
  border: 1px solid #e3e3e3;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #494949;
  transition: all 100ms ease-out;
}
.map-wrapper-header form button:hover {
  background-color: #e3e3e3;
}
.map-wrapper h3 {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 24px;
  font-weight: bold;
  color: #494949;
  letter-spacing: -0.3px;
  margin-right: 58px;
  margin-bottom: 30px;
}
.map-wrapper .map {
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  transition: all 300ms ease-out;
  min-height: 400px;
  height: calc(100vh - 192px);
}
@media only screen and (max-width: 600px) {
  .map-wrapper .map {
    height: calc(100vh - 145px);
  }
}
.map-wrapper .map.full-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.map-wrapper .google-map.loading {
  -webkit-filter: grayscale(70%) blur(2px);
          filter: grayscale(70%) blur(2px);
}
#my-tiles-list h3 {
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 0 20px;
  text-align: center;
  font-size: 24px;
  height: auto;
}

#tips div {
  padding: 16px 0;
}
.my-tile {
  padding: 16px 0;
  border-bottom: 1px solid #ebebeb;
}
.my-tile:first-child {
  padding-top: 0;
}
.my-tile:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.my-tile-param {
  display: flex;
  margin-bottom: 6px;
}
.my-tile-param:last-child {
  margin-bottom: 0;
}
.my-tile-label {
  width: 40px;
  object-fit: contain;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #939393;
}
.my-tile-value {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-weight: bold;
  color: #494949;
}
.my-tile-code {
  color: #025089;
  text-decoration: underline;
}
.eth-symbol {
  font-family: Helvetica;
}
.map-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
}
.CheckMetamask {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-image: url(/static/media/metamask.2980f875.png);
  background-repeat: no-repeat;
  background-size: 85px 85px;
  background-position: center top;
  padding-top: 90px;
  width: 300px;
  margin: auto;
  text-align: center;
  color: #494949;
  -webkit-animation: anim 0s 0.5s forwards;
          animation: anim 0s 0.5s forwards;
  visibility: hidden;
}
.CheckMetamask h2 {
  font-size: 24px;
  margin-bottom: 16px;
}
.CheckMetamask a {
  color: #025089;
}

@-webkit-keyframes anim {
  to {
    visibility: visible;
  }
}

@keyframes anim {
  to {
    visibility: visible;
  }
}
.stats-table {
  margin-bottom: 20px;
}
.stats-table tr {
  line-height: 22px;
}
.stats-table th {
  height: 30px;
  text-align: left;
}
.stats-table td {
  padding: 0 20px 0 0;
}
.stats-table td:last-child {
  padding-right: 0;
}
.stats-table td.tileColor {
  width: 23px;
}
.stats-table .disclosures button {
  margin: 7px 9px 0 2px;
}

#sidebar {
  max-width: 265px;
}
#sidebar-tabs button {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: bold;
  color: #494949;
  letter-spacing: -0.3px;
  margin-bottom: 20px;
  margin-right: 25px;
  margin-left: 15px;
  height: 46px;
  line-height: 1;
  color: #025089;
  background-color: transparent;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
}
#sidebar-tabs button.selected {
  color: #494949;
  text-decoration: none;
  font-weight: bold;
  cursor: default;
}
#sidebar-body {
  position: relative;
  border-radius: 6px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
  padding: 28px;
  padding-right: 5px;
  min-height: 400px;
  height: calc(100vh - 192px);
  overflow: auto;
}
@media only screen and (max-width: 820px) {
  #sidebar {
    display: none;
  }
}
.full-screen-button {
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: #ffffff;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e3e3e3;
  border-radius: 2px;
  z-index: 999;
  padding: 8px;
  width: 34px;
  height: 34px;
}
.drawer {
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 15;
  width: 300px;
  padding: 48px 20px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  -webkit-animation: slideIn 200ms;
          animation: slideIn 200ms;
}
.drawer-back {
  position: absolute;
  top: 16px;
  right: 16px;
}

@-webkit-keyframes slideIn {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideIn {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.buy-info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 24px;
}
.buy-info-table tr td {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-weight: bold;
  text-align: right;
  color: #494949;
  position: relative;
  padding: 4px;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}
.buy-info-table tr td:first-child {
  color: #939393;
  font-weight: normal;
  text-align: left;
  padding-left: 0;
}
.buy-info-table tr td:last-child {
  padding-right: 0;
}
.buy-info-table tr td a {
  color: #025089;
}

.buy-info-pending {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 22px;
  color: #ee7904;
}
.change-price-form label {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: 700;
  color: #494949;
  margin-right: 20px;
}
.change-price-form input {
  width: 150px;
  height: 50px;
  object-fit: contain;
  border-radius: 6px;
  border: solid 1px #e5e5e5;
  background-color: #f3f3f3;
  font-size: 20px;
  color: #494949;
  font-weight: 700;
  text-align: center;
  outline: none;
  margin-right: 20px;
}
.change-price-form p {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 22px;
}
.change-price-form p.invalid {
  color: #ee7904;
}
.change-price-form button, .change-price-form a {
  width: 160px;
  height: 50px;
  background-color: #025089;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}
.change-price-form button:disabled, .change-price-form a:disabled {
  background-color: #717171;
}
.change-price-form a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  color: #025089;
  border: 1px solid #025089;
}
.change-price-form-buttons {
  display: flex;
  margin-top: 20px;
}
.change-price-form-buttons button[type=submit] {
  margin-right: 10px;
}
#info {
  width: 206px;
  height: 135px;
}
#info div, #info a {
  width: 103px;
  height: 17px;
  display: inline-block;
  margin-bottom: 7px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-overflow: ellipsis;
  overflow: hidden;
}
#info .key {
  color: #939393;
}
#info .value {
  font-weight: bold;
  text-align: right;
  color: #494949;
}
#info .pluslink {
  font-weight: bold;
  text-align: right;
  color: #025089;
}

#buttons {
  text-align: center;
  display: flex;
  justify-content: center;
}
#buttons .button-footnote {
  margin-top: 1px;
  font-size: 12px;
}
#buttons button, #buttons a {
  width: 160px;
  height: 50px;
  background-color: #025089;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}
#buttons a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  background-color: #025089;
  border-radius: 7px;
}
#solana-offer {
  border: 1px solid #DFDFDF;
  border-radius: 7px;
  width: 100%;
  padding: 10px;
}
#solana-offer input {
  width: 150px;
  height: 50px;
  object-fit: contain;
  border-radius: 6px;
  border: solid 1px #e5e5e5;
  background-color: #f3f3f3;
  font-size: 20px;
  color: #494949;
  font-weight: 700;
  text-align: center;
  outline: none;
  margin-left: 18px;
}
#solana-offer label {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: 700;
  color: #494949;
  margin-left: 20px;
}
#solana-offer p {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 16px;
  text-align: center;
  margin: 8px;
}
#solana-offer p:first-child {
  font-size: 18px;
  margin-bottom: 10px;
}
#solana-offer #buttons {
  margin-top: 10px;
}
#solana-offer #buttons button, #solana-offer a {
  width: 100%;
  height: 50px;
  background-color: #025089;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.2px;
  text-align: center;
  color: #ffffff;
}

#buttons-hidden {
  display: none;
}

.buy-info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 14px;
}
.buy-info-table tr td {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-weight: bold;
  text-align: right;
  color: #494949;
  position: relative;
  padding: 4px;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}
.buy-info-table tr td:first-child {
  color: #939393;
  font-weight: normal;
  text-align: left;
  padding-left: 0;
}
.buy-info-table tr td:last-child {
  padding-right: 0;
}
.buy-info-table tr td a {
  color: #025089;
}

.buy-info-pending {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 22px;
  color: #ee7904;
}
.tiles {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
}
.tiles > div {
  flex-grow: 1;
  flex-basis: 0;
}
.tiles > div:first-child {
  margin-right: 20px;
}
#logo {
  height: 66px;
}
#alert {
  background-color: #025089;
  text-align: center;
  padding: 5px 8px 8px;
  color: white;
}

.header {
  height: 90px;
  background-color: #ffffff;
  padding: 0 35px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  font-size: 24px;
  font-weight: bold;
  text-align: right;
  color: #494949;
}
.header a {
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #025089;
  margin-left: 20px;
}
.header #dash {
  font-size: 18px;
  font-weight: normal;
  margin-left: 20px;
}
@media only screen and (max-width: 900px) {
  .header #dash {
    display: none;
  }
}
@media only screen and (max-width: 820px) {
  .header #menu a.telegram {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .header #menu a.twitter {
    display: none;
  }
}
