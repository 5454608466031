.tiles {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;

  & > div {
    //width: 48%;
    flex-grow: 1;
    flex-basis: 0;

    &:first-child {
      margin-right: 20px;
    }
  }
}
