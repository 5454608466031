@import "../../mixins";
@import "../../variables";

#solana-offer {
  border: 1px solid #DFDFDF;
  border-radius: 7px;
  width: 100%;
  padding: 10px;

  input {
    width: 150px;
    height: 50px;
    object-fit: contain;
    border-radius: 6px;
    border: solid 1px #e5e5e5;
    background-color: #f3f3f3;
    font-size: 20px;
    color: $text-dark;
    font-weight: 700;
    text-align: center;
    outline: none;
    margin-left: 18px;
    // margin-right: 20px;
  }

  label {
    @include DefaultFont;
    font-size: 16px;
    font-weight: 700;
    color: $text-dark;
    margin-left: 20px;
  }

  p {
    @include DefaultFont;
    font-size: 16px;
    text-align: center;
    margin: 8px;
  }  

  p:first-child {
    font-size: 18px;
    margin-bottom: 10px;
  }

  #buttons {
    margin-top: 10px;
  }

  #buttons button, a {
    width: 100%;
    height: 50px;

    background-color: #025089;

    @include DefaultFont;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0.2px;
    text-align: center;
    color: #ffffff;

  }

}

#buttons-hidden {
  display: none;
}


.buy-info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 14px;

  tr {
    td {
      @include DefaultFont;
      font-weight: bold;
      text-align: right;
      color: #494949;
      position: relative;
      padding: 4px;

      overflow: hidden;
      text-overflow: clip;
      white-space: nowrap;

      &:first-child {
        color: #939393;
        font-weight: normal;
        text-align: left;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      a {
        color: #025089;
      }

    }
  }
}

.buy-info-pending {
  @include DefaultFont;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 22px;
  color: #ee7904;
}
