$bg-light: #fff;

$text-dark: #494949;
$text-blue: #025089;

$border-color: #eeeeee;

$border: 1px solid $border-color;
$border-radius: 2px;
$box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
